import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import MainTabs from '../modules/tabs/components/MainTabs.vue'
import Home from '../views/HomePage.vue'
import { useAuthenticationStore } from '@/stores/authentication'
import { useGeneralStore } from '@/stores/general'
import { MOBILE_1, MOBILE_2 } from '../globals/RegexCollection'
import { useUserStore } from '@/stores/user'
import { ToastColor, ToastDuration, useToast } from '@/globals/Toast'
import { useAdminStore } from '@/stores/admin'
import { UserAuthorization } from '@/models/interfaces/Administration'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginPage.vue'),
    meta: { requireAuth: false },
  },
  {
    path: '/register/',
    name: 'Registrierung',
    component: () => import('@/views/NewRegistrationPage.vue'),
    meta: { requireAuth: false },
    redirect: '/register/hello',
    children: [
      {
        path: '/register/hello',
        component: () => import('@/views/RegisterHello.vue'),
        name: 'Hallo',
        meta: { requireAuth: false },
      },
      {
        path: '/register/account-data',
        component: () => import('@/views/RegisterAccountData.vue'),
        name: 'Account Daten',
        meta: { requireAuth: false },
      },
      {
        path: '/register/personal-data',
        component: () => import('@/views/RegisterPersonalData.vue'),
        name: 'Persöhnliche Daten',
        meta: { requireAuth: false },
      },
      {
        path: '/register/location-data',
        component: () => import('@/views/RegisterLocationData.vue'),
        name: 'Adress Daten',
        meta: { requireAuth: false },
      },
      {
        path: '/register/gtc',
        component: () => import('@/views/RegisterGTC.vue'),
        name: 'AGBs',
        meta: { requireAuth: false },
      },
    ],
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('@/views/ForgotPasswordPage.vue'),
    meta: { requireAuth: false },
  },
  {
    path: '/reset-password/:requestToken',
    component: () => import('@/views/ResetPasswordPage.vue'),
    props: true,
    name: 'Reset Password',
    meta: { requireAuth: false },
  },
  {
    path: '/mobile-devices-only',
    component: () => import('@/views/MobileDevicePage.vue'),
    name: 'Device Only',
    meta: { requireAuth: false },
  },
  {
    path: '/success',
    component: () => import('@/views/SuccessPage.vue'),
    name: 'Success',
    meta: { requireAuth: true },
  },
  {
    path: '/tabs/',
    component: MainTabs,
    redirect: '/tabs/home',
    name: 'Home',
    meta: { requireAuth: true },
    children: [
      {
        path: '/tabs/home',
        component: Home,
        name: 'Dashboard',
      },
      {
        path: '/tabs/book-game',
        component: () => import('@/views/BookGamePageReimagined.vue'),
        name: 'Buchen',
      },
      {
        path: '/tabs/book-game-reimagined',
        component: () => import('@/views/BookGamePageReimagined.vue'),
        name: 'BuchenNeu',
      },
      {
        path: '/tabs/alerts',
        component: () => import('@/views/AlertPage.vue'),
        name: 'Nachrichten',
      },
      {
        path: '/tabs/invitations',
        component: () => import('@/views/UserInvitations.vue'),
        name: 'Invitations',
      },
      {
        path: '/tabs/admin/',
        component: () => import('@/modules/tabs/components/PassThrough.vue'),
        meta: { requireAdmin: true },
        redirect: '/tabs/home',
        children: [
          {
            path: '/tabs/admin/admin',
            component: () => import('@/views/AdminPage.vue'),
            name: 'Administration',
          },
          {
            path: '/tabs/admin/useradmin',
            component: () => import('@/views/UserAdministrationPage.vue'),
            name: 'Benutzerverwaltung',
          },
          {
            path: '/tabs/admin/useradmin/:id',
            component: () => import('@/views/UserAdministrationUserPage.vue'),
            name: 'Adminbenutzerprofilansicht',
          },
          {
            path: '/tabs/admin/useradmin/group/:id',
            component: () => import('@/views/UserAdministrationGroupPage.vue'),
            name: 'Gruppenverwaltung',
          },
          {
            path: '/tabs/admin/adminmessages',
            component: () => import('@/views/SendMessagePage.vue'),
            name: 'Nachrichten',
          },
          {
            path: '/tabs/admin/app-bookings',
            component: () => import('@/views/AdminBookingPage.vue'),
            name: 'App Buchungen',
          },
          {
            path: '/tabs/admin/message-settings',
            component: () => import('@/views/MessageSettingsPage.vue'),
            name: 'Vorlagen/Signaturen konfigurieren',
          },
          {
            path: '/tabs/admin/adminstatistics',
            component: () => import('@/views/AdminStatistics.vue'),
            name: 'Adminstatistik',
            meta: { requireAuth: true, requireAdmin: true },
          },
        ],
      },
      {
        path: '/tabs/invitations',
        component: () => import('@/views/UserInvitations.vue'),
        name: 'Einladungen',
      },
      {
        path: '/tabs/recharge-credits/checkout/:id/result',
        component: () => import('@/views/CheckoutResultPage.vue'),
        name: 'Übersicht',
      },
      {
        path: '/tabs/register-payment/result',
        component: () => import('@/views/RegisterPaymentResultPage.vue'),
        name: 'Zahlungsinformationen Ergebnis',
      },
      {
        path: '/tabs/recharge-credits',
        component: () => import('@/views/RechargeCreditsPage.vue'),
        name: 'Guthaben aufladen',
      },
      {
        path: '/tabs/recharge-credits/checkout/:amount',
        component: () => import('@/views/CheckoutPage.vue'),
        name: 'Checkout',
        props: true,
      },
      {
        path: '/tabs/register-payment',
        component: () => import('@/views/RegisterPaymentPage.vue'),
        name: 'Zahlungsinformationen hinzufügen',
      },
      {
        path: '/tabs/my-payments',
        component: () => import('@/views/UserPaymentsPage.vue'),
        name: 'Meine Zahlungen',
      },
      {
        path: '/tabs/sent-invitations',
        component: () => import('@/views/SentInvitationsPage.vue'),
        name: 'Versendete Einladungen',
      },
      {
        path: '/tabs/help',
        component: () => import('@/views/HelpPage.vue'),
        name: 'Hilfe',
      },
      {
        path: '/tabs/profile',
        component: () => import('@/views/UserProfile.vue'),
        name: 'Profil',
      },
      {
        path: '/tabs/bookings',
        component: () => import('@/views/UserBookingsPage.vue'),
        name: 'Buchungen',
      },
      {
        path: '/tabs/house-rules',
        component: () => import('@/views/HouseRulesPage.vue'),
        name: 'Hausregeln',
      },
      {
        path: '/tabs/players',
        component: () => import('@/views/PlayerListPage.vue'),
        name: 'Spielerliste',
      },
      {
        path: '/tabs/players/:id',
        component: () => import('@/views/PlayerProfilePage.vue'),
        name: 'Benutzerprofilansicht',
      },
      {
        path: '/tabs/level-overview',
        component: () => import('@/views/LevelOverviewPage.vue'),
        name: 'Level übersicht',
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from) => {
  /** remove existing paymentWidget in order to load a fresh one */
  const paymentWidgetElement = document.getElementById('payment-widget')
  if (paymentWidgetElement) {
    paymentWidgetElement.remove()
  }

  const authenticationStore = useAuthenticationStore()

  const genStore = useGeneralStore()
  genStore.showProgressBar = true

  const userStore = useUserStore()

  if (to.name === 'Login') {
    authenticationStore.$reset()
    userStore.$reset()
    return
  }

  const adminStore = useAdminStore()

  // Check if upcoming page requires Authentication.
  if (to.matched.some(record => record.meta.requireAuth)) {
    const userId = authenticationStore.userData?.id

    if (!userId) {
      return { path: '/login' }
    }

    // Check if user is blocked.
    if (
      (await adminStore.getUserAuthTypeByUserId()) == UserAuthorization.Blocked
    ) {
      if (
        to.path == '/tabs/recharge-credits' ||
        to.path == '/tabs/home' ||
        to.name == 'Checkout' ||
        to.path == '/tabs/checkout-result' ||
        to.path == '/tabs/profile' ||
        to.path == '/tabs/register-payment' ||
        to.path == '/tabs/my-payments' ||
        to.path == '/tabs/help'
      ) {
        return
      } else {
        useToast(
          `Sie sind für diese Aktion gesperrt.`,
          ToastDuration.DANGER,
          ToastColor.DANGER
        )
        genStore.showProgressBar = false

        return { path: '/tabs/home' }
      }
    }
    const isAdmin = await genStore.isAdmin(+userId)

    // Check if upcoming page requires admin privileges.
    if (to.matched.some(record => record.meta.requireAdmin)) {
      if (!isAdmin) {
        useToast(
          `Dazu sind Sie nicht berechtigt!`,
          ToastDuration.DANGER,
          ToastColor.DANGER
        )
        return { path: '/login' }
      } else {
        return
      }
    }

    if (isAdmin) {
      return
    }
    /** ensure mobile device except for admin users */
    const userAgent = navigator.userAgent || navigator.vendor
    if (
      MOBILE_1.test(userAgent) ||
      // eslint-disable-next-line no-useless-escape
      MOBILE_2.test(userAgent.substring(0, 4))
    ) {
      return
    }

    return { path: '/mobile-devices-only' }
  }
})

export default router
