import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue'

/* Import Pinia */
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

/* Import axios global config */
// import { axiosSetUp } from "./globals/axios";

import axiosInstance from '../src/globals/axios'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

// import 'leaflet/dist/leaflet.css'
// import 'leaflet/dist/leaflet.js'
/* Theme variables */
import './theme/variables.css'

/* PWA / ServiceWorker */
import './registerServiceWorker'
import { useApplicationStore } from './stores/app'

/** PrimeVue / Datatables */
import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'primeicons/primeicons.css'

import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'de',
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
  .use(pinia)
  .use(IonicVue)
  .use(router)
  .use(PrimeVue, { // PrimeVue config adjusted to german language
    locale: {
      accept: 'Ja',
      addRule: 'Regel hinzufügen',
      am: 'am',
      apply: 'Übernehmen',
      cancel: 'Abbrechen',
      choose: 'Auswählen',
      chooseDate: 'Datum wählen',
      chooseMonth: 'Monat wählen',
      chooseYear: 'Jahr wählen',
      clear: 'Löschen',
      completed: 'Abgeschlossen',
      contains: 'Enthält',
      custom: 'Benutzerdefiniert',
      dateAfter: 'Datum ist nach',
      dateBefore: 'Datum ist vor',
      dateFormat: 'dd.mm.yy',
      dateIs: 'Datum ist',
      dateIsNot: 'Datum ist nicht',
      dayNames: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
      emptyFilterMessage: 'Keine Ergebnisse gefunden',
      emptyMessage: 'Keine Einträge gefunden',
      emptySearchMessage: 'Keine Ergebnisse gefunden',
      emptySelectionMessage: 'Kein ausgewähltes Element',
      endsWith: 'Endet mit',
      equals: 'Ist gleich',
      fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      filter: 'Filtern',
      firstDayOfWeek: 1,
      gt: 'Größer als',
      gte: 'Größer oder gleich',
      lt: 'Kleiner als',
      lte: 'Kleiner oder gleich',
      matchAll: 'Passt auf alle',
      matchAny: 'Passt auf einige',
      medium: 'Mittel',
      monthNames: [
        'Jänner',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
      monthNamesShort: [
        'Jän',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
      nextDecade: 'Nächstes Jahrzehnt',
      nextHour: 'Nächste Stunde',
      nextMinute: 'Nächste Minute',
      nextMonth: 'Nächster Monat',
      nextSecond: 'Nächste Sekunde',
      nextYear: 'Nächstes Jahr',
      noFilter: 'Kein Filter',
      notContains: 'Enthält nicht',
      notEquals: 'Ist ungleich',
      now: 'Jetzt',
      passwordPrompt: 'Passwort eingeben',
      pending: 'Ausstehend',
      pm: 'pm',
      prevDecade: 'Vorheriges Jahrzehnt',
      prevHour: 'Vorherige Stunde',
      prevMinute: 'Vorherige Minute',
      prevMonth: 'Vorheriger Monat',
      prevSecond: 'Vorherige Sekunde',
      prevYear: 'Vorheriges Jahr',
      reject: 'Nein',
      removeRule: 'Regel entfernen',
      searchMessage: '{0} Ergebnisse verfügbar',
      selectionMessage: '{0} Elemente ausgewählt',
      showMonthAfterYear: false,
      startsWith: 'Beginnt mit',
      strong: 'Stark',
      today: 'Heute',
      upload: 'Hochladen',
      weak: 'Schwach',
      weekHeader: 'KW',
      aria: {
        cancelEdit: 'Änderungen abbrechen',
        close: 'Schließen',
        collapseLabel: 'Einklappen',
        collapseRow: 'Zeile reduziert',
        editRow: 'Zeile bearbeiten',
        expandLabel: 'Ausklappen',
        expandRow: 'Zeile erweitert',
        falseLabel: 'Falsch',
        filterConstraint: 'Filterbeschränkung',
        filterOperator: 'Filteroperator',
        firstPageLabel: 'Erste Seite',
        gridView: 'Rasteransicht',
        hideFilterMenu: 'Filtermenü ausblenden',
        jumpToPageDropdownLabel: 'Zum Dropdown-Menü springen',
        jumpToPageInputLabel: 'Zum Eingabefeld springen',
        lastPageLabel: 'Letzte Seite',
        listView: 'Listenansicht',
        moveAllToSource: 'Alle zur Quelle bewegen',
        moveAllToTarget: 'Alle zum Ziel bewegen',
        moveBottom: 'Zum Ende bewegen',
        moveDown: 'Nach unten bewegen',
        moveTop: 'Zum Anfang bewegen',
        moveToSource: 'Zur Quelle bewegen',
        moveToTarget: 'Zum Ziel bewegen',
        moveUp: 'Nach oben bewegen',
        navigation: 'Navigation',
        next: 'Nächste',
        nextPageLabel: 'Nächste Seite',
        nullLabel: 'Nicht ausgewählt',
        otpLabel: 'Bitte geben Sie das Einmalkennwortzeichen {0} ein.',
        pageLabel: 'Seite {page}',
        passwordHide: 'Passwort verbergen',
        passwordShow: 'Passwort anzeigen',
        previous: 'Vorherige',
        previousPageLabel: 'Vorherige Seite',
        rotateLeft: 'Nach links drehen',
        rotateRight: 'Nach rechts drehen',
        rowsPerPageLabel: 'Zeilen pro Seite',
        saveEdit: 'Änderungen speichern',
        scrollTop: 'Nach oben scrollen',
        selectAll: 'Alle Elemente ausgewählt',
        selectLabel: 'Auswählen',
        selectRow: 'Zeile ausgewählt',
        showFilterMenu: 'Filtermenü anzeigen',
        slide: 'Folie',
        slideNumber: '{slideNumber}',
        star: '1 Stern',
        stars: '{star} Sterne',
        trueLabel: 'Wahr',
        unselectAll: 'Alle Elemente abgewählt',
        unselectLabel: 'Auswahl aufheben',
        unselectRow: 'Zeile abgewählt',
        zoomImage: 'Bild vergrößern',
        zoomIn: 'Vergrößern',
        zoomOut: 'Verkleinern',
      },
    },
  })

router.isReady().then(async () => {
  const appStore = useApplicationStore()
  try {
    const appInfo = await axiosInstance.get(
      process.env.VUE_APP_BACKEND_URL + '/application/app-info'
    )
    Object.assign(appStore.$state, appInfo.data)

    /* attempt for dynamic manifest / manifest per client */
    // const stringManifest = JSON.stringify(manifest);
    // const blob = new Blob([stringManifest], { type: "application/json" });
    // const manifestUrl = URL.createObjectURL(blob);
    // document.querySelector("#test")?.setAttribute("href", manifestUrl);
    //
  } catch (error) {
    // console.log(error);
  }
  app.mount('#app')
})
