import {
  UserBooking,
  APIUserBooking,
  UserBookingPoolTable,
  UserBookingParkingSpotBooked,
  UserBookingInvitation,
} from '@/models/interfaces/User'

export function transformUserBooking(apiBooking: APIUserBooking): UserBooking {
  const userBooking = {
    id: apiBooking.id,
    site: apiBooking.site.name,
    from: apiBooking.from,
    info: apiBooking.info,
    to: apiBooking.to,
    users: apiBooking.users.map(
      (user: UserBookingInvitation) => user.user.username
    ),
    price: apiBooking.price,
    tables: apiBooking.tables.map(
      (table: UserBookingPoolTable) => table.tableId
    ),
    parkingSpots: apiBooking.parkingSpots.map(
      (parkingSpot: UserBookingParkingSpotBooked) =>
        parkingSpot.parkingSpot.identifier
    ),
    state: apiBooking.state,
    bookeeId: apiBooking.bookeeId,
    bookeeName: apiBooking.bookee.username,
    cancellationDeadline: apiBooking.site.cancellationDeadline,
  }
  return userBooking
}

export function transformUserBookings(
  apiBookings: APIUserBooking[]
): UserBooking[] {
  const userBookings: UserBooking[] = []
  for (const booking of apiBookings) {
    userBookings.push(transformUserBooking(booking))
  }
  return userBookings
}
