/**
 * see: https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
 */
export const PHONE_NUMBER = new RegExp(
  /^[+]\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
)

/**
 * Gets a password validation with regex.
 * At least one upper case English letter, (?=.*?[A-Z])
 * At least one lower case English letter, (?=.*?[a-z])
 * At least one digit, (?=.*?[0-9])
 * At least one special character, (?=.*?[#?!@$%^&*-])
 * Minimum eight in length .{8,} (with the anchors)
 */
export const PASSWORD = new RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
)

export const MOBILE_1 = new RegExp(
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xino/i
)

export const MOBILE_2 = new RegExp(
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xino/i
)

export const IMAGE_FILE = new RegExp(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i)

export const FULL_NAME = new RegExp(/^[a-zA-Z\s]*$/)
