/* eslint-disable no-useless-catch */
import { axiosDelete, axiosGet, axiosPost } from '@/globals/AxiosHelper'
import { Site } from '@/models/interfaces/Administration'
import { ImageTypes, Rules } from '@/models/interfaces/Application'
import { SelectOption } from '@/models/interfaces/SelectOption'
import axios, { AxiosResponse } from 'axios'
import { defineStore } from 'pinia'
import { useAdminStore } from './admin'
import { useAuthenticationStore } from './authentication'
import { useUserStore } from './user'

export const useApplicationStore = defineStore('application', {
  state: () => ({
    id: 0,
    mediaLogin: '',
    mediaRegistration: '',
    mediaForgotPassword: '',
    mediaPWALarge: '',
    mediaPWASmall: '',
    themeColor: '',
    backgroundColor: '',
    minimumAge: 0,
    rules: [] as Rules[],
    sites: [] as Site[],
    clientId: 0,
  }),

  persist: true,
  getters: {
    getSitesSelect(state: any): SelectOption[] {
      return state.sites.map((data: any) => ({
        id: data.id,
        value: data.name,
      }))
    },
    getAllSites(state: any) {
      return state.sites
    },
  },
  actions: {
    getSiteNameById(siteId: number) {
      return this.sites.find((site: Site) => {
        return site.id === siteId
      })
    },
    getSiteByName(siteName: string): Site {
      return this.sites.find((site: Site) => {
        return site.name === siteName
      })
    },
    getRulesBySiteId(siteId: number): Rules {
      return this.rules.find((r: any) => r.siteId == siteId) as Rules
    },

    async getRules(): Promise<Rules[]> {
      try {
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/application/rules`,
          {
            params: {
              appId: this.id,
            },
          }
        )
        // console.log("rules", response.data);
        this.rules = response.data
        return response.data
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async getSites(): Promise<Site[]> {
      try {
        // console.log("appid", this.id);
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/site`,
          {
            params: {
              appId: this.id,
            },
          }
        )

        this.sites = response.data
        return response.data
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Uploads an image to minio + db.
     * @param image
     * @param imageType
     * @returns The PresignedUrl for the newly uploaded image.
     */
    async uploadImage(image: any, imageType: ImageTypes): Promise<string> {
      try {
        const authStore = useAuthenticationStore()

        const formData = new FormData()
        // File name needs to be the same as the fileName in the backend-controller.
        formData.append(imageType, image)
        // If it's a profile picture, we need the user-ID, since profile picture is user related.
        if (imageType === ImageTypes.profilePicture) {
          formData.append('userId', authStore.userData?.id?.toString() ?? '')
        }
        formData.append('appId', this.$state.id.toString())
        formData.append('imageType', imageType)
        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/application/image`,
          formData
        )

        if (imageType == ImageTypes.profilePicture) {
          const userStore = useUserStore()
          userStore.$patch(state => {
            state.userData!.profilePicture = response.data
          })
        }

        return response.data
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Gets an image from minio-bucket.
     * @param imageType
     * @returns
     */
    async getImage(imageType: ImageTypes): Promise<string> {
      try {
        const authStore = useAuthenticationStore()
        const response: any = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/application/image`,
          {
            params: {
              imageType,
              userId: authStore.userData?.id,
              appId: this.$state.id,
            },
          }
        )
        return (response as AxiosResponse).data
      } catch (error) {
        // console.log(error);
        throw error
      }
    },

    /**
     * Deletes an image from minio-bucket and DB.
     * @param imageType
     * @returns
     */
    async deleteImage(imageType: ImageTypes): Promise<void> {
      try {
        const authStore = useAuthenticationStore()
        const response: any = await axiosDelete(
          `${process.env.VUE_APP_BACKEND_URL}/application/image`,
          {
            params: {
              appId: this.$state.id,
              userId: authStore.userData?.id,
              imageType,
            },
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async getMinioObjects(): Promise<string> {
      try {
        const adminStore = useAdminStore()
        const response: any = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/application/minio-objects/${this.id}/${adminStore.selectedSiteId}`
        )
        console.log(response)
        const minioResponse = await axios.get(response.data.url, {
          responseType: 'blob',
        })

        const blob = new File(
          [minioResponse.data],
          `${this.id}-data-${response.data.timeStamp}.zip`
        )

        const link = document.createElement('a')
        link.download = blob.name
        link.href = window.URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        await axiosDelete(
          `${process.env.VUE_APP_BACKEND_URL}/application/minio-objects`,
          {
            params: {
              file: blob.name,
            },
          }
        )

        return response.data.timeStamp
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async getDatabaseBackup(): Promise<void> {
      try {
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/application/database/${this.id}`
        )

        const minioResponse = await axios.get(response.data, {
          responseType: 'blob',
        })

        const blob = new File([minioResponse.data], `${this.id}-backup.json`)

        const link = document.createElement('a')
        link.download = blob.name
        link.href = window.URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async getLastDownloadedTimeStamp(): Promise<{
      [id: string]: { timeStamp: string }
    }> {
      try {
        const adminStore = useAdminStore()

        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/application/download-timeStamps/${adminStore.selectedSiteId}`
        )

        return response.data
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async testFunc() {
      try {
        console.log('calling test endpoint')
        console.log(
          await axiosGet(`${process.env.VUE_APP_BACKEND_URL}/application/test`)
        )
      } catch (error) {
        console.error(error)
      }
    },
  },
})
