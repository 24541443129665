/* eslint-disable no-useless-catch */
import { defineStore } from 'pinia'
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from '@/globals/AxiosHelper'
import {
  ApiAccessConfiguration,
  ApiEmailServerConfiguration,
  ApiInvoiceConfiguration,
  ApiSmsGatewayConfigration,
  AppConfiguration,
  BookingConfiguration,
  BookingRules,
  BusinessHours,
  CompanyConfiguration,
  InvoiceConfiguration,
  LevelConfiguration,
  License,
  ParkingSpotConfiguration,
  UserAuthorization,
  Site,
  Summary,
  UserToAuthorize,
  GroupTableGroup,
  UserTableUser,
  AdminMessagePreset,
  AdminMessageSignature,
  MessageData,
  BookingVm,
} from '@/models/interfaces/Administration'
import { useApplicationStore } from './app'
import { Rules } from '@/models/interfaces/Application'
import { useUserStore } from './user'
import { useAuthenticationStore } from './authentication'

export const useAdminStore = defineStore('admin', {
  state: () => ({
    summary: null as Summary | null,
    selectedSiteId: 0, // Selected siteID in adminpage.
    sites: undefined as Site[] | undefined,
    licenses: undefined as License | undefined,
    businessHours: undefined as BusinessHours[] | undefined,
    bookingRules: undefined as BookingRules | undefined,
    userToAuthorize: undefined as UserToAuthorize[] | undefined,
    rules: undefined as Rules[] | undefined,
    userAdministrationUsers: [] as UserTableUser[],
    userAdministrationGroups: [] as GroupTableGroup[],
    messagePresets: [] as AdminMessagePreset[],
    messageSignatures: [] as AdminMessageSignature[],
    appBookings: [] as BookingVm[],
    resetGroupTable: undefined as boolean | undefined,
    companyConfigurations: [] as CompanyConfiguration[],
  }),
  persist: {
    storage: window.sessionStorage,
  },
  getters: {
    getCompanyConfigurations: state => state.companyConfigurations,
    getCurrentSiteConfiguration: state => {
      return state.companyConfigurations.find(
        config => config.siteId === state.selectedSiteId
      )
    },
    getMinimumGameTime: state => state.bookingRules?.minBookingTime,
    getStoreBusinessHours: state => state.businessHours,
    getHtmlRules(state): string {
      return (
        state.rules?.find(r => r.siteId == state.selectedSiteId)?.rules ?? ''
      )
    },
    getAdminGroups: state =>
      state.userAdministrationGroups.map(group => {
        return {
          id: group.id,
          name: group.name,
          users: group.users,
        }
      }),
    getAdminMessagePresets: state => {
      return state.messagePresets.map((preset: AdminMessagePreset) => {
        return {
          id: preset.id,
          name: preset.presetName,
          message: preset.message,
        }
      })
    },
    getAdminMessageSignatures: state => {
      return state.messageSignatures.map((signature: AdminMessageSignature) => {
        return {
          id: signature.id,
          name: signature.name,
          signature: signature.signature,
        }
      })
    },
    getPresetById: state => (presetId: number) => {
      return state.messagePresets.find((preset: AdminMessagePreset) => {
        return preset.id === presetId
      })
    },
    getSignatureById: state => (signatureId: number) => {
      return state.messageSignatures.find(
        (signature: AdminMessageSignature) => {
          return signature.id === signatureId
        }
      )
    },
  },
  actions: {
    /**
     * Post house-rules
     * @param rules
     */
    async postHouseRules(rules: string): Promise<void> {
      try {
        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/rules`,
          {
            siteId: this.selectedSiteId,
            rules: rules,
          }
        )
        this.rules = response.data
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Get house-rules.
     * @returns
     */
    async getHouseRules(): Promise<Rules[]> {
      try {
        const appStore = useApplicationStore()
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/rules`,
          {
            params: {
              appId: appStore.id,
            },
          }
        )
        this.rules = response.data
        return response.data
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Accept/Declines an user-authorization.
     * @param userId
     * @param responseAuth
     * @returns
     */
    async respondToUserAuthorization(
      userId: number,
      authorization: UserAuthorization
    ): Promise<void> {
      try {
        // console.log("AUTH", authorization);
        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/authorization`,
          {
            userId: userId,
            authorization: authorization,
          }
        )
        return response.data
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Gets all user, which are not yet authorized.
     * @returns
     */
    async getUsersByAuthType(
      type: UserAuthorization
    ): Promise<UserToAuthorize[]> {
      try {
        const appStore = useApplicationStore()
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/authorization`,
          {
            params: {
              appId: appStore.id,
              type: type,
            },
          }
        )

        this.userToAuthorize = response.data as UserToAuthorize[]
        return this.userToAuthorize
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async getUserAuthTypeByUserId(): Promise<UserAuthorization> {
      try {
        const authStore = useAuthenticationStore()
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/authorizationType/${authStore.userData?.id}`,
          {}
        )

        const uStore = useUserStore()
        uStore.authorizationType = response.data
        return response.data
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    /**
     * Get all sites belonging to logged in user/admin.
     * @returns
     */
    async getSites(): Promise<Array<Site>> {
      try {
        const appStore = useApplicationStore()
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/site`,
          {
            params: {
              appId: appStore.id,
            },
          }
        )
        this.sites = response.data as Site[]
        return this.sites
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Get booking-rules by site-ID.
     * @param siteId
     * @returns
     */
    async getBookingRules(siteId: number): Promise<BookingRules> {
      try {
        // console.log("bookingrules", siteId);
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/booking/rules`,
          {
            params: {
              siteId: siteId,
            },
          }
        )
        // console.log("resposne", response);

        this.bookingRules = response.data as BookingRules
        return this.bookingRules
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Gets summary information.
     * @returns
     */
    async getSummary(): Promise<Summary> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/summary`,
          { params: { siteId: this.selectedSiteId, appId: appStore.id } }
        )

        return response.data as Summary
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    getCompanyConfigurationForSite(
      siteId: number
    ): CompanyConfiguration | undefined {
      return this.companyConfigurations.find(
        (config: CompanyConfiguration) => config.siteId === siteId
      )
    },

    /**
     * Gets general information about a site.
     * @returns
     */
    async getGeneral(): Promise<void> {
      try {
        const appStore = useApplicationStore()
        if (!appStore.id) {
          throw new Error(`Application ID not found`)
        }
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/general`,
          { params: { appId: appStore.id } }
        )

        this.companyConfigurations = response.data
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Update general informations.
     * @param information
     * @returns
     */
    async postGeneral(information: CompanyConfiguration): Promise<void> {
      try {
        const appStore = useApplicationStore()
        if (!appStore.id) {
          throw new Error(`Application ID not found`)
        }

        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/general`,
          {
            appId: appStore.id,
            siteId: this.selectedSiteId,
            siteName: information.siteName,
            company: information.company,
            address: information.address,
            locality: information.locality,
            country: information.country,
            businessTimes: information.businessTimes,
            links: information.links,
            generalInfo: information.generalInfo,
            clientMail: information.clientMail,
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Update business-hours.
     * @param businessHours
     * @returns
     */
    async postBusinessHours(businessHours: BusinessHours[]): Promise<void> {
      try {
        businessHours[0].siteId = this.selectedSiteId
        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/business-hours`,
          businessHours
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Get business-hours.
     * @returns
     */
    async getBusinessHours(siteId?: number): Promise<BusinessHours[]> {
      try {
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/business-hours`,
          { params: { siteId: siteId ?? this.selectedSiteId } }
        )
        this.businessHours = response.data as BusinessHours[]
        // console.log("BUSINESSOHURS123", this.businessHours);
        return this.businessHours
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Update level-configuration.
     * @param level
     * @returns
     */
    async postLevelConfiguration(level: LevelConfiguration): Promise<void> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/level`,
          {
            app: {
              id: appStore.id,
            },
            visits: {
              10: level.visits[10] ?? '',
              25: level.visits[25] ?? '',
              50: level.visits[50] ?? '',
              75: level.visits[75] ?? '',
              100: level.visits[100] ?? '',
              150: level.visits[150] ?? '',
              200: level.visits[200] ?? '',
              300: level.visits[300] ?? '',
            },
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Get Level-configuration.
     * @returns
     */
    async getLevelConfiguration(): Promise<LevelConfiguration> {
      try {
        const appStore = useApplicationStore()
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/level`,
          {
            params: {
              appId: appStore.id,
            },
          }
        )
        return response.data as LevelConfiguration
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Post app-configuration.
     * @param appConfig
     * @returns
     */
    async postAppConfiguration(appConfig: AppConfiguration): Promise<void> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/application`,
          {
            app: {
              id: appStore.id,
              url: appConfig.app.url,
              name: appConfig.app.name,
            },
            color: {
              hexCodeBackground: appConfig.color.hexCodeBackground,
              hexCodeTheme: appConfig.color.hexCodeTheme,
            },
            poolStart: {
              memberNumber: appConfig.poolStart?.memberNumber,
              groupNumber: appConfig.poolStart?.groupNumber,
            },
            minimumAge: appConfig.minimumAge,
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Get application configuration.
     * @returns
     */
    async getAppConfiguration(): Promise<AppConfiguration> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/application`,
          { params: { appId: appStore.$state.id } }
        )
        return response.data as AppConfiguration
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Post booking-settings.
     * @param settings
     * @returns
     */
    async postBookingSettings(
      settings: BookingConfiguration | undefined
    ): Promise<void> {
      try {
        if (!settings) {
          throw new Error()
        }

        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/booking`,
          {
            siteId: this.selectedSiteId,
            maxTables: settings.maxTables,
            maxMembers: settings.maxMembers,
            maxPlayerPerTable: settings.maxPlayerPerTable,
            maxGameTimePerBooking: settings.maxGameTimePerBooking,
            minimumBookingTimeMinute: settings.minimumBookingTimeMinute,
            timeBetweenGames: settings.timeBetweenGames,
            cancelDeadlineMinute: settings.cancelDeadlineMinute,
            prohibitBookingDays: settings.prohibitBookingDays,
            notifyOnBooking: settings.notifyOnBooking,
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Get booking-settings.
     * @returns
     */
    async getBookingSettings(): Promise<BookingConfiguration> {
      try {
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/booking`,
          {
            params: {
              siteId: this.selectedSiteId,
            },
          }
        )

        return response.data as BookingConfiguration
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Deletes an prohibit-booking entry in the DB.
     * @param date
     * @returns
     */
    async deleteExistingProhibitBooking(date: Date | undefined): Promise<void> {
      try {
        if (!date) {
          throw new Error()
        }

        const response = await axiosDelete(
          `${process.env.VUE_APP_BACKEND_URL}/administration/booking`,
          {
            params: {
              date: date,
              siteId: this.selectedSiteId,
            },
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Get invoice-settings.
     * @returns
     */
    async getInvoice(): Promise<InvoiceConfiguration> {
      try {
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/invoice`,
          {
            params: {
              siteId: this.selectedSiteId,
            },
          }
        )
        // console.log("response", response);
        return response.data as InvoiceConfiguration
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Post invoice-settings.
     * @param settings
     * @returns
     */
    async postInvoice(settings: InvoiceConfiguration): Promise<void> {
      try {
        // console.log("SETTINGS", settings);
        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/invoice`,
          {
            siteId: this.selectedSiteId,
            membershipCostPerMonth: settings.membershipCostPerMonth,
            tableCost: settings.tableCost,
            tableCostGeneral: settings.tableCostGeneral,
            qHourCostGeneral: settings.qHourCostGeneral,
            qHourCost: settings.qHourCost,
            serviceCost: settings.serviceCost,
            parkingSpotCost: settings.parkingSpotCost,
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Get API-Access settings.
     * @returns
     */
    async getApiAccess(): Promise<ApiAccessConfiguration> {
      try {
        const appStore = useApplicationStore()
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/api-access`,
          {
            params: {
              appId: appStore.id,
            },
          }
        )

        return response.data as ApiAccessConfiguration
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Post API-Access settings.
     * @param settings
     * @returns
     */
    async postApiAccess(settings: ApiAccessConfiguration): Promise<void> {
      try {
        const appStore = useApplicationStore()
        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/api-access`,
          {
            appId: appStore.id,
            serverUrl: settings.serverUrl,
            user: settings.user,
            password: settings.password,
            add1: settings.add1,
            add2: settings.add2,
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Get API-SMS settings.
     * @returns
     */
    async getApiSms(): Promise<ApiSmsGatewayConfigration> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/api-sms`,
          {
            params: {
              appId: appStore.id,
            },
          }
        )

        return response.data as ApiSmsGatewayConfigration
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Post API-SMS settings.
     * @param settings
     * @returns
     */
    async postApiSms(settings: ApiSmsGatewayConfigration): Promise<void> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/api-sms`,
          {
            appId: appStore.id,
            serverUrl: settings.serverUrl,
            user: settings.user,
            password: settings.password,
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Get API-Email settings.
     * @returns
     */
    async getApiEmail(): Promise<ApiEmailServerConfiguration> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/api-email`,
          {
            params: {
              appId: appStore.id,
            },
          }
        )

        return (await response.data) as ApiEmailServerConfiguration
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Post API-Email settings.
     * @param settings
     * @returns
     */
    async postApiEmail(settings: ApiEmailServerConfiguration): Promise<void> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/api-email`,
          {
            appId: appStore.id,
            smtpServer: settings.smtpServer,
            user: settings.user,
            password: settings.password,
            sender: {
              name: settings.sender.name,
              eMail: settings.sender.eMail,
            },
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },
    /**
     * Get API-Invoice settings.
     * @returns
     */
    async getApiInvoice(): Promise<ApiInvoiceConfiguration> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/api-invoice`,
          {
            params: {
              appId: appStore.id,
            },
          }
        )

        return (await response.data) as ApiInvoiceConfiguration
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Post API-Invoice settings.
     * @param settings
     * @returns
     */
    async postApiInvoice(settings: ApiInvoiceConfiguration): Promise<void> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/api-invoice`,
          {
            appId: appStore.id,
            server: settings.server,
            username: settings.username,
            password: settings.password,
            odooCustomerNumber: settings.odooCustomerNumber,
            prefixTwo: settings.prefixTwo,
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Get licenses and how much is used of each.
     * @returns
     */
    async getLicenses(): Promise<License> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/license`,
          {
            params: {
              appId: appStore.id,
            },
          }
        )
        this.licenses = response.data as License
        return this.licenses
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Gets all parkingspots.
     * @returns
     */
    async getParkingSpots(): Promise<ParkingSpotConfiguration> {
      try {
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/administration/parking-spots`,
          {
            params: {
              siteId: this.selectedSiteId,
            },
          }
        )

        return response.data as ParkingSpotConfiguration
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Post parkingspots-settings.
     * @param ps
     */
    async postParkingSpots(ps: ParkingSpotConfiguration): Promise<void> {
      try {
        await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/parking-spots`,
          {
            siteId: this.selectedSiteId,
            parkingSpots: ps.parkingSpots,
            parkingSpotMessage: ps.parkingSpotMessage,
            isFree: ps.isFree,
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },
    async getShootersUserData() {
      try {
        const appStore = useApplicationStore()

        const appId = appStore.id

        if (!appId) {
          throw new Error(`Application ID not set`)
        }
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/user/get-useradministration-users`,
          {
            withCredentials: true,
            params: {
              appId,
            },
          }
        )
        this.$patch(state => {
          state.userAdministrationUsers = response.data
        })
      } catch (error) {
        // console.log(error);
        throw error
      }
    },
    async getShootersGroupData() {
      try {
        const appStore = useApplicationStore()

        const appId = appStore.clientId

        if (!appId) {
          throw new Error(`Client ID not set`)
        }
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/group/get-useradministration-groups`,
          {
            withCredentials: true,
            params: {
              appId,
            },
          }
        )

        this.$patch(state => {
          state.userAdministrationGroups = response.data
        })
      } catch (error) {
        // console.log(error);
        throw error
      }
    },
    async getMessageSignatures() {
      try {
        const appStore = useApplicationStore()

        const clientId = appStore.clientId

        if (!clientId) {
          throw new Error(`Application ID not set`)
        }
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/signature/get-admin-signatures`,
          {
            withCredentials: true,
            params: {
              clientId,
            },
          }
        )

        this.$patch(state => {
          state.messageSignatures = response.data
        })
      } catch (error) {
        // console.log(error);
        throw error
      }
    },
    async getMessagePresets() {
      try {
        const appStore = useApplicationStore()

        const clientId = appStore.id

        if (!clientId) {
          throw new Error(`Application ID not set`)
        }
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/message-preset/get-admin-message-presets`,
          {
            withCredentials: true,
            params: {
              clientId,
            },
          }
        )

        this.$patch(state => {
          state.messagePresets = response.data
        })
      } catch (error) {
        // console.log(error);
        throw error
      }
    },
    async invitePlayer(invitationData: {
      name: string
      email: string
      phone: string
    }) {
      try {
        const appStore = useApplicationStore()
        const clientId = appStore.clientId
        if (!clientId) {
          throw new Error(`Application ID not set`)
        }
        const res = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/communication/invite-user`,
          {
            clientId,
            name: invitationData.name,
            email: invitationData.email,
            phone: invitationData.phone,
          }
        )

        return res
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async accept(userId: number) {
      await this.respondToUserAuthorization(userId, UserAuthorization.Accepted)
      // await getUserToAuthorize();
    },

    async decline(userId: number) {
      await this.respondToUserAuthorization(userId, UserAuthorization.Rejected)
      // await getUserToAuthorize();
    },

    async block(userId: number) {
      await this.respondToUserAuthorization(userId, UserAuthorization.Blocked)
      // await getUserToAuthorize();
    },

    async createGroup(groupData: { name: string; discount: number }) {
      try {
        const appStore = useApplicationStore()
        const appId = appStore.id
        if (!appId) {
          throw new Error(`Application ID not set`)
        }
        /** attach appId to payload */
        await axiosPost(`${process.env.VUE_APP_BACKEND_URL}/group`, {
          ...groupData,
          app: appId,
        })
      } catch (error) {
        // console.log(error);
        throw error
      }
    },

    async removePlayerFromGroup(groupId: number, userId: number) {
      try {
        await axiosPatch(
          `${process.env.VUE_APP_BACKEND_URL}/group/${groupId}/removePlayer`,
          { userId }
        )
      } catch (error) {
        throw error
      }
    },

    async getGroupUsers(groupId: number) {
      return (
        await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/group/${groupId}/users`
        )
      ).data
    },

    async updateGroup(
      groupId: number,
      data: { name: string; discount: number }
    ) {
      return await axiosPatch(
        `${process.env.VUE_APP_BACKEND_URL}/group/${groupId}`,
        data
      )
    },

    async getAllUsers() {
      const appStore = useApplicationStore()
      const appId = appStore.id
      if (!appId) {
        throw new Error(`App ID not set`)
      }
      return (await axiosGet(`${process.env.VUE_APP_BACKEND_URL}/user`)).data
    },

    async addPlayerToGroup(userId: number, groupId: number) {
      try {
        await axiosPatch(
          `${process.env.VUE_APP_BACKEND_URL}/user/${userId}/addToGroup`,
          {
            groupId,
          }
        )
      } catch (error) {
        throw error
      }
    },

    async sendSMS(messageData: MessageData) {
      try {
        await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/communication/sms`,
          messageData
        )
      } catch (error) {
        throw error
      }
    },

    async sendSingleSMS(message: string, number: string) {
      await axiosPost(
        `${process.env.VUE_APP_BACKEND_URL}/communication/single-sms`,
        { message, number }
      )
    },

    async sendEmail(messageData: MessageData) {
      try {
        await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/communication/email`,
          messageData
        )
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async getAppBookings(): Promise<BookingVm[]> {
      try {
        const appStore = useApplicationStore()
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/booking/app-bookings`,
          {
            params: {
              appId: appStore.id,
            },
          }
        )
        this.appBookings = response.data
        return this.appBookings
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async deleteGroupById(groupId: number): Promise<void> {
      try {
        const response = await axiosDelete(
          `${process.env.VUE_APP_BACKEND_URL}/administration/group/${groupId}`
        )
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async postSummarySettings(summary: Summary): Promise<void> {
      try {
        const appStore = useApplicationStore()
        await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/administration/summary/${appStore.id}`,
          {
            // city: summary.city,
            // companyName: summary.companyName,
            eMail: summary.eMail,
            // houseNumber: summary.houseNumber,
            // postalCode: summary.postalCode,
            // siteName: summary.siteName,
            // street: summary.street,
            // vat: summary.vat,
          }
        )
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async deleteUser(userId: number) {
      try {
        if (!userId) {
          throw new Error(`User ID missing from payload`)
        }
        const res = await axiosDelete(
          `${process.env.VUE_APP_BACKEND_URL}/user/admin-delete-user`,
          {
            params: {
              userId,
            },
          }
        )

        return res
      } catch (error) {
        console.error(error)
        throw error
      }
    },
  },
})
