import router from '@/router'
import axiosInstance from './axios'
import { RefreshTokenService } from './api/authentication'
import { ToastColor, ToastDuration, useToast } from './Toast'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

const authService = new RefreshTokenService()

export async function axiosGet(
  endpoint: string,
  params?: AxiosRequestConfig | undefined
): Promise<AxiosResponse> {
  try {
    /**
     * first, try to send HTTP.GET to endpoint
     */
    return await axiosInstance.get(endpoint, params)
  } catch (error: any) {
    /**
     * if the response has status 401 - Unauthorized ...
     */
    if (error.response.status.toString() === '401') {
      try {
        /**
         * ... try invoking /refresh endpoint in order to refresh JW Tokens
         */
        await authService.refreshToken()
      } catch (error) {
        /**
         *  should this call fail again, redirect to /login (either the request failed in general,
         *  OR the refresh token has expired too)
         */
        useToast(
          //`Sitzung abgelaufen. Bitte melden Sie sich erneut an`,
          `Sitzung abgelaufen / Update verfügbar. Bitte melden Sie sich erneut an`,
          ToastDuration.WARNING,
          ToastColor.WARNING
        )
        setTimeout(() => {
          window.location.href = '/login'
          throw error
        }, 3000)
        // router.push('/login')
      }
      try {
        /**
         * otherwise, repeat the call to the initial endpoint
         */
        return await axiosInstance.get(endpoint, params)
      } catch (error) {
        useToast(
          `Anfrage fehlgeschlagen. Bitte versuchen Sie es später erneut`,
          ToastDuration.WARNING,
          ToastColor.WARNING
        )
        throw error
      }
    }
    throw error
  }
}

export async function axiosPost(
  endpoint: string,
  data = {}
): Promise<AxiosResponse> {
  try {
    return await axiosInstance.post(endpoint, data)
  } catch (error: any) {
    if (error.response.status.toString() === '401') {
      try {
        await authService.refreshToken()
      } catch (error) {
        useToast(
          `Sitzung abgelaufen. Bitte melden Sie sich erneut an`,
          ToastDuration.WARNING,
          ToastColor.WARNING
        )
        router.push('/login')
        throw error
      }
      try {
        return await axiosInstance.post(endpoint, data)
      } catch (error) {
        useToast(
          `Anfrage fehlgeschlagen. Bitte versuchen Sie es später erneut`,
          ToastDuration.WARNING,
          ToastColor.WARNING
        )
        throw error
      }
    }
    throw error
  }
}

export async function axiosPut(
  endpoint: string,
  data = {}
): Promise<AxiosResponse> {
  try {
    return await axiosInstance.put(endpoint, data)
  } catch (error: any) {
    if (error.response.status.toString() === '401') {
      try {
        await authService.refreshToken()
      } catch (error) {
        useToast(
          `Sitzung abgelaufen. Bitte melden Sie sich erneut an`,
          ToastDuration.WARNING,
          ToastColor.WARNING
        )
        router.push('/login')
        throw error
      }
      try {
        return await axiosInstance.put(endpoint, data)
      } catch (error) {
        useToast(
          `Anfrage fehlgeschlagen. Bitte versuchen Sie es später erneut`,
          ToastDuration.WARNING,
          ToastColor.WARNING
        )
        throw error
      }
    }
    throw error
  }
}

export async function axiosPatch(
  endpoint: string,
  data = {}
): Promise<AxiosResponse> {
  try {
    return await axiosInstance.patch(endpoint, data)
  } catch (error: any) {
    if (error.response.status.toString() === '401') {
      try {
        await authService.refreshToken()
      } catch (error) {
        useToast(
          `Sitzung abgelaufen. Bitte melden Sie sich erneut an`,
          ToastDuration.WARNING,
          ToastColor.WARNING
        )
        router.push('/login')
        throw error
      }
      try {
        return await axiosInstance.patch(endpoint, data)
      } catch (error) {
        useToast(
          `Anfrage fehlgeschlagen. Bitte versuchen Sie es später erneut`,
          ToastDuration.WARNING,
          ToastColor.WARNING
        )
        throw error
      }
    }
    throw error
  }
}

export async function axiosDelete(
  endpoint: string,
  config?: AxiosRequestConfig | undefined
): Promise<AxiosResponse> {
  try {
    return await axiosInstance.delete(endpoint, config)
  } catch (error: any) {
    if (error.response.status.toString() === '401') {
      try {
        await authService.refreshToken()
      } catch (error) {
        useToast(
          `Sitzung abgelaufen. Bitte melden Sie sich erneut an`,
          ToastDuration.WARNING,
          ToastColor.WARNING
        )
        router.push('/login')
        throw error
      }
      try {
        return await axiosInstance.delete(endpoint, config)
      } catch (error) {
        useToast(
          `Anfrage fehlgeschlagen. Bitte versuchen Sie es später erneut`,
          ToastDuration.WARNING,
          ToastColor.WARNING
        )
        throw error
      }
    }
    throw error
  }
}
