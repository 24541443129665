import { toastController } from '@ionic/vue'

export async function useToast(
  message: string,
  duration: ToastDuration,
  color: ToastColor
) {
  const toast = await toastController.create({
    message: message,
    duration: duration,
    position: 'top',
    color: color,
    buttons: [
      {
        role: 'cancel',
      },
    ],
  })
  return toast.present()
}

export enum ToastColor {
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

export enum ToastDuration {
  DANGER = 1500,
  SUCCESS = 1500,
  WARNING = 1500,
  INFO = 1500,
}
