/* eslint-disable no-useless-catch */
import { defineStore } from 'pinia'
import { Login, Register } from '@/models/interfaces/Authentication'
import { User } from '@/models/interfaces/User'
import { InvitationState } from '@/models/interfaces/Booking'
import axiosInstance from '@/globals/axios'
import { axiosPost } from '@/globals/AxiosHelper'
import { useAdminStore } from './admin'
import { useUserStore } from './user'
import { useApplicationStore } from './app'

export const useAuthenticationStore = defineStore('authentication', {
  state: () => ({
    isLoggedIn: false as boolean,
    userData: undefined as User | undefined,
  }),

  persist: {
    storage: window.sessionStorage,
    paths: ['userData', 'isLoggedIn'],
  },

  getters: {
    getPendingRequests(state) {
      let pendingBookingsCount = 0
      state.userData?.bookings.forEach(element => {
        if (element.accepted == InvitationState.pending) {
          pendingBookingsCount++
        }
      })
      return pendingBookingsCount
    },

    getLevel(state) {
      return state.userData?.level.level ?? 'Fehler'
    },

    getAmountOfBookings(state) {
      return state.userData?.bookings.length ?? 0
    },

    getAmountOfGames(state) {
      return state.userData?.games.length ?? 0
    },

    getUserData(state) {
      return state.userData ?? 'Fehler'
    },
  },

  actions: {
    async login(user: Login) {
      try {
        const adminStore = useAdminStore()
        const response = await axiosInstance.post<User>(
          `${process.env.VUE_APP_BACKEND_URL}/auth/signIn`,
          {
            username: user.username,
            password: user.password,
          }
        )
        // console.log(response.data);

        this.userData = (response as any).data

        this.isLoggedIn = true
        adminStore.$state.selectedSiteId = this.userData?.siteId ?? 0

        const userStore = useUserStore()
        userStore.setUserData(response.data)
      } catch (error) {
        // console.error(error);
        this.isLoggedIn = false
        throw error
      }
    },

    async logout() {
      try {
        const response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/auth/logout`,
          {
            user: { userId: this.userData?.id },
          }
        )
        this.isLoggedIn = false
        // console.log(response);
      } catch (error) {
        // console.log(error);
        throw error
      }
    },

    /**
     *  TODO: hash password before transmit
     * @param user
     * @returns
     */
    async register(user: Register) {
      try {
        const appStore = useApplicationStore()

        await axiosInstance.post(
          `${process.env.VUE_APP_BACKEND_URL}/auth/signup`,
          {
            birthDate: user.birthdate,
            name: user.firstName,
            surname: user.lastName,
            username: user.username,
            password: user.password,
            phone: user.phoneNumber,
            email: user.email,
            street: user.address.street,
            houseNumber: user.address.houseNumber,
            zip: user.address.postalCode,
            locality: user.address.place,
            country: user.address.countryCode,
            appId: appStore.id,
          }
        )
      } catch (error) {
        // console.log(error);
        throw error
      }
    },

    /**
     * Sends a link to the given email to reset the password.
     * @param email
     */
    async forgotPassword(email: string): Promise<void> {
      try {
        const appStore = useApplicationStore()
        await axiosInstance.get(
          `${process.env.VUE_APP_BACKEND_URL}/auth/forgot-password`,
          {
            params: {
              email,
              appId: appStore.id,
            },
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    /**
     * Resets the password.
     * @param token
     * @param password
     */
    async resetPassword(token: string, password: string): Promise<void> {
      try {
        await axiosInstance.put(
          `${process.env.VUE_APP_BACKEND_URL}/auth/reset-password`,
          {
            token: token,
            password: password,
          }
        )
      } catch (error) {
        // console.error(error);
        throw error
      }
    },
  },
})
