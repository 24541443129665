/* eslint-disable no-useless-catch */
import axiosInstance from '@/globals/axios'

/*
 * This class is needed to prevent a circular dependency between axios.ts and authentication.ts (store)
 */
export class RefreshTokenService {
  async refreshToken() {
    try {
      const response = await axiosInstance.get(
        // "http://localhost:3000/auth/refresh"
        process.env.VUE_APP_BACKEND_URL + '/auth/refresh'
      )
      // if (response.status === 200) {
      //   console.log("status OK");
      // }
      return response
    } catch (error) {
      // console.log(error);
      throw error
    }
  }
}
