export enum ImageTypes {
  login = 'login',
  registration = 'registration',
  profilePicture = 'profilePicture',
  forgotPassword = 'forgotPassword',
  pwaLarge = 'pwaLarge',
  pwaSmall = 'pwaSmall',
}

export class Image {
  file = ''
  objectUrl = ''
}

export class Rules {
  siteId: number
  rules: string
}
