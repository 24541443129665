/* Import Axios */
import axios from 'axios'
import router from '@/router/index'
import { RefreshTokenService } from '@/globals/api/authentication'

const axiosInstance = axios.create()
axiosInstance.defaults.withCredentials = true
// axiosInstance.defaults.url = "http://localhost:3000";
axiosInstance.defaults.url = process.env.VUE_APP_BACKEND_URL

const authService = new RefreshTokenService()

const retries = 0

// axiosInstance.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   async function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     if (
//       error.config.url ===
//       "http://localhost:3000/auth/log-out"
//     ) {
//       console.log("LOGOUTOUT");
//       router.push("/login");
//       return Promise.reject(error);
//     }

//     if (error.response.status === 401 && retries > 0) {
//       retries = 0;
//       router.push("/login");
//       return;
//     }
//     if (error.response.status === 401 && retries <= 0) {
//       console.log(error);
//       // error.config.retry = true;
//       let res;
//       try {
//         res = await authService.refreshToken();
//       } catch (error) {
//         retries++;
//         return Promise.reject(error);
//       }
//       return res;
//       // return Promise.reject(error);
//     }

//     return Promise.reject(error);
//   }
// );

export default axiosInstance
