/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // console.log(
      //   'App is being served from cache by a service worker.\n' +
      //     'For more details, visit https://goo.gl/AFskqB',
      // )
    },
    registered(reg: any) {
      // console.log('Service worker has been registered.', reg)
      // check for SW updates every 5 minutes
      setInterval(() => {
        console.log('updating service worker')
        reg.update()
      }, 5 * 60 * 1000)
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      // console.log('New content is available; please refresh.')
      window.dispatchEvent(new Event('swUpdated'))
    },
    offline() {
      // console.log(
      //   'No internet connection found. App is running in offline mode.',
      // )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
