/* eslint-disable no-useless-catch */
import { defineStore } from 'pinia'
import { useAuthenticationStore } from './authentication'
import { axiosGet, axiosPatch, axiosPost } from '@/globals/AxiosHelper'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  UpdateUserDto,
  User,
  Credits,
  ChangeUserContactInfo,
  ChangeUserSocialMedia,
  ChangeUserVehicles,
  UserSocialMediaSubset,
  UserBooking,
  Player,
  ChangePassword,
  ChangeSocialMedia,
  ChangeContactInfo,
  ChangeVehicle,
  ActiveUser,
  UpdateDataVisibility,
} from '@/models/interfaces/User'
import { transformUserBookings } from '@/helpers/bookingHelper'
import { useApplicationStore } from './app'
import { Role } from '@/models/interfaces/Role'
import { parseISO } from 'date-fns'
import { UserAuthorization } from '@/models/interfaces/Administration'
import { FilterPlayerList } from '@/models/interfaces/Filter'

export const useUserStore = defineStore('user', {
  state: () => ({
    invitationsCounter: 0,
    userData: undefined as User | undefined,
    credits: undefined as Credits | undefined,
    allUsers: undefined as Player[] | undefined,
    userBookings: [] as UserBooking[],
    activeUsers: [] as ActiveUser[],
    authorizationType: undefined as UserAuthorization | undefined,
    playerListFilter: undefined as FilterPlayerList | undefined,
    playerListFilterLocalities: [] as string[],
  }),
  persist: {
    storage: window.sessionStorage,
  },

  getters: {
    getStoreUserData: state => state.userData,
    hasUserRoles: state => (userId: number, requiredRole: string) => {
      return state.userData?.roles.find(
        (role: Role) => role.role === requiredRole
      )
    },

    getPlayers(state): Player[] {
      return state.allUsers as Player[]
    },
    getActivePlayerById: state => (uid: number) => {
      return state.activeUsers.find(user => user.id === uid)
    },
    getUserID: state => state.userData?.id,
    getStoreUserBookings: state => state.userBookings,
    // filter UserBookings for 'startingDate' is in the future
    getStoreFutureUserBookings: state =>
      state.userBookings
        .filter(
          (booking: UserBooking) =>
            new Date(booking.from).getTime() >= new Date().getTime() &&
            booking.state === 'active'
        )
        .filter((booking: UserBooking) => booking.state == 'active')
        // sort future bookings from oldest to newest
        .sort((bookingA: UserBooking, bookingB: UserBooking) => {
          const fromA = new Date(bookingA.from)
          const fromB = new Date(bookingB.from)
          if (fromA < fromB) {
            return -1
          }
          if (fromA > fromB) {
            return 1
          }
          return 0
        }),
    getCancelledUserBookings: state => {
      return state.userBookings
        .filter((booking: UserBooking) => booking.state === 'cancelled')
        .sort((bookingA: UserBooking, bookingB: UserBooking) => {
          const fromA = new Date(bookingA.from)
          const fromB = new Date(bookingB.from)
          if (fromA < fromB) {
            return 1
          }
          if (fromA > fromB) {
            return -1
          }
          return 0
        })
    },
    // filter UserBookings for 'startingDate' is in the past
    getStorePastUserBookings: state =>
      state.userBookings
        .filter(
          (booking: UserBooking) =>
            new Date(booking.from).getTime() < new Date().getTime() &&
            booking.state === 'active'
        )
        // sort past bookings from newest to oldest
        .sort((bookingA: UserBooking, bookingB: UserBooking) => {
          const fromA = new Date(bookingA.from)
          const fromB = new Date(bookingB.from)
          if (fromA < fromB) {
            return 1
          }
          if (fromA > fromB) {
            return -1
          }
          return 0
        }),

    getUserVehicles: state => {
      return {
        vehicleOne: state.userData?.vehicleOne,
        vehicleTwo: state.userData?.vehicleTwo,
      }
    },

    getActivePlayers: state => {
      return state.activeUsers
    },
    getBookingCount: state => {
      return state.userBookings.filter(b => b.state == 'active').length
    },

    /**
     * Filtered Userlist.
     * @param state
     * @returns
     */
    getAllUsersToInvite(state): Player[] {
      const authStore = useAuthenticationStore()
      return state.allUsers
        ?.filter(user => user.id !== authStore.userData?.id)
        .map(p => ({ id: p.id, username: p.username })) as Player[]
    },

    getUserSocialMedia: (state): ChangeUserSocialMedia => {
      const a = new Map()
      state.userData?.userSocialMedia.forEach((usm: UserSocialMediaSubset) => {
        a.set(usm.socialMedia.name, usm.identifier)
      })

      a.set('socialMediaActive', state.userData?.socialMediaActive)
      a.set('favouriteDring', state.userData?.favouriteDrink)

      return Object.fromEntries(a)
    },

    getBookingsForChart(state): Date[] {
      const dates: Date[] = []

      state.userBookings.forEach(b => {
        if (typeof b.from === 'string') {
          b.from = new Date(b.from)
        }

        if (b.from.getFullYear() == new Date().getFullYear()) {
          if (b.state == 'active') {
            dates.push(b.from)
          }
        }
      })
      return dates
    },
  },
  actions: {
    getStoreUserBookingById(bookingId: number) {
      return this.userBookings.find(
        (booking: UserBooking) => booking.id === bookingId
      )
    },

    /**
     * Get user by ID. Doesn't fetch new user from DB.
     * @param id
     * @returns
     */
    getUserById(id: number): Player {
      const user = this.allUsers?.find(u => u.id == id)
      return {
        id: user?.id ?? 0,
        username: user?.username ?? '',
      }
    },

    /**
     * Get all users by appID.
     * @returns
     */
    async getAllUser(): Promise<Player[]> {
      try {
        const appStore = useApplicationStore()

        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/user/get-user`,
          {
            params: {
              appId: appStore.id,
            },
          }
        )

        this.allUsers = response.data as Player[]

        return this.allUsers as Player[]
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async getLevel(): Promise<string> {
      try {
        const authStore = useAuthenticationStore()

        const axiosConfig: AxiosRequestConfig = {
          params: { userId: authStore.userData?.id },
        }
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/user/get-level`,
          axiosConfig
        )

        return response.data[0].level.level
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async getCredits(): Promise<Credits> {
      try {
        const authStore = useAuthenticationStore()

        const axiosConfig: AxiosRequestConfig = {
          params: { userId: authStore.userData?.id },
        }

        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/payment-user-credits`,
          axiosConfig
        )
        this.$patch(state => {
          const credit: Credits = response.data
          credit.creditAmountTotal = +credit.creditAmountTotal.toFixed(2)
          credit.debitAmountTotal = +credit.debitAmountTotal.toFixed(2)
          state.credits = credit
        })
        return this.credits as Credits
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async updateUser(data: UpdateUserDto) {
      let response: AxiosResponse
      try {
        response = await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/user/update-user`,
          data
        )
        // CASE: server side error
        if (response?.status) {
          // console.log("There was an error when updating user");
          return { error: response.statusText }
        }
        return response
      } catch (error) {
        // CASE: unexpected FE error
        // console.log(`Unable to update user`);
        // console.log(error);
        throw error
      }
    },
    async getUserBookings() {
      try {
        if (!this.getUserID) {
          throw new Error(`Session expired`)
        }
        const response: AxiosResponse = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/user/${this.getUserID}/bookings`
        )
        // console.log(response.data);
        this.$patch(state => {
          state.userBookings = transformUserBookings(response.data)
        })
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    setUserData(userData: User) {
      this.$patch(state => {
        state.userData = userData
      })
    },

    async changePassword(pw: ChangePassword) {
      try {
        const res = await axiosPatch(
          `${process.env.VUE_APP_BACKEND_URL}/user/${this.getUserID}/change-password`,
          {
            currentPassword: pw.currentPassword,
            newPassword: pw.newPassword,
            confirmPassword: pw.confirmPassword,
          }
        )

        this.$patch(state => {
          state.userData = res.data
        })
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async changeSocialMedia(user: ChangeSocialMedia): Promise<User> {
      try {
        const appStore = useApplicationStore()

        const res = await axiosPatch(
          `${process.env.VUE_APP_BACKEND_URL}/user/${this.getUserID}/change-social-media`,
          {
            appId: appStore.id,
            username: user.username,
            facebook: user.facebook,
            twitter: user.twitter,
            instagram: user.instagram,
            tiktok: user.tiktok,
            youtube: user.youtube,
            favouriteDrink: user.favouriteDrink,
            socialMediaActive: user.socialMediaActive,
          }
        )
        if (this.userData?.socialMediaActive) {
          this.$patch(state => {
            state.userData!.socialMediaActive = user.socialMediaActive
          })
        }
        return res.data
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async changeContactInfo(info: ChangeContactInfo): Promise<void> {
      try {
        const res = await axiosPatch(
          `${process.env.VUE_APP_BACKEND_URL}/user/${this.getUserID}/change-contact-info`,
          {
            firstName: info.firstName,
            lastName: info.lastName,
            street: info.street,
            houseNumber: info.houseNumber,
            postalCode: info.postalCode,
            place: info.place,
            company: info.company,
            country: info.country,
            taxNumber: info.taxNumber,
            website: info.website,
            industry: info.industry,
            phoneNumber: info.phoneNumber,
            email: info.email,
          }
        )
        this.$patch(state => {
          state.userData = res.data
        })
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async changeVehicles(vehicle: ChangeVehicle): Promise<void> {
      try {
        await axiosPatch(
          `${process.env.VUE_APP_BACKEND_URL}/user/${this.getUserID}/change-vehicles`,
          {
            vehicleOne: vehicle.vehicleOne,
            vehicleTwo: vehicle.vehicleTwo,
          }
        )
        this.$patch(state => {
          state.userData!.vehicleOne = vehicle.vehicleOne
          state.userData!.vehicleTwo = vehicle.vehicleTwo
        })
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async getAdminUserById(userId: number): Promise<any> {
      try {
        const res = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/user/userExists`,
          {
            params: {
              userId,
            },
          }
        )
        // console.log("RESULT", res.data);
        return res.data
      } catch (error) {
        // console.log(error);
        throw error
      }
    },

    async getUserGroups(userId: number) {
      return (
        await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/user/${userId}/groups`
        )
      ).data
    },

    async getActiveUsers() {
      try {
        const appStore = useApplicationStore()
        const appId = appStore.id
        if (!appId) {
          throw new Error(`Application ID not set`)
        }

        this.activeUsers = (
          await axiosGet(
            `${process.env.VUE_APP_BACKEND_URL}/application/${appId}/players`
          )
        ).data
        console.log('ACTIVE PLAYERS', this.activeUsers)
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async updateDataVisibility(data: UpdateDataVisibility): Promise<void> {
      try {
        const result = await axiosPatch(
          `${process.env.VUE_APP_BACKEND_URL}/user/${this.userData?.id}/data-visibility`,
          {
            isAgePublic: data.isAgePublic,
            isLocalityPublic: data.isLocalityPublic,
            isMailPublic: data.isMailPublic,
            isPhonePublic: data.isPhonePublic,
            isNamePublic: data.isNamePublic,
            isSurnamePublic: data.isSurnamePublic,
          }
        )
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async getDataVisbility(): Promise<UpdateDataVisibility> {
      try {
        const result = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/user/${this.userData?.id}/data-visibility`
        )

        return result.data
      } catch (error) {
        console.error(error)
        throw error
      }
    },
  },
})
