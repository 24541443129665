/* eslint-disable no-useless-catch */
import { defineStore } from 'pinia'
import { SelectOption } from '@/models/interfaces/SelectOption'
import { axiosGet } from '@/globals/AxiosHelper'
import { User } from '@/models/interfaces/User'
import { Country } from '@/models/interfaces/Country'
import { AxiosResponse } from 'axios'
import { LevelOverview } from '@/models/interfaces/Level'
import { useAuthenticationStore } from './authentication'
import { useUserStore } from './user'

export const useGeneralStore = defineStore('general', {
  state: () => ({
    users: [] as User[],
    showProgressBar: false,
    countries: [] as Country[],
    countriesLoaded: false,
  }),

  getters: {
    getStoreCountries: state => state.countries,
    getStoreCountriesLoaded: state => state.countries.length > 0,
  },
  actions: {
    async getCountries() {
      try {
        const response: AxiosResponse = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/country/get-countries`,
          {}
        )
        if (response.status === 200 && response.data.length > 0) {
          this.$patch(state => {
            state.countries = response.data
          })
          this.$patch(state => {
            state.countriesLoaded = true
          })
          return this.countries
        }
        throw new Error(`Something went wrong fetching Countries`)
      } catch (error) {
        // console.log("error in api refresh" + error);
        throw error
      }
    },

    async getUser() {
      let response: AxiosResponse
      try {
        response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/user/get-user`,
          {}
        )
        // console.log("in general.ts getUser()");
        // console.log("response first call to /user/get-user");
        // console.log(response);
        if (response.data && response.data.length > 0) {
          const selectOption = new Array<SelectOption>()
          response.data.forEach((element: { id: any; username: any }) => {
            selectOption.push({ id: element.id, value: element.username })
          })
          return selectOption
        }
      } catch (error) {
        // console.error("oje");
        throw error
      }
    },

    getCountryByCountryCode(searchCountryCode: string) {
      return this.countries.find(
        (country: Country) => country.countryCode === searchCountryCode
      )
    },

    getFilteredCountries(searchString: string) {
      return this.countries.filter((country: Country) => {
        return country.countryDE.toLowerCase().includes(searchString)
      })
    },

    async isAdmin(userId: number) {
      try {
        return (
          await axiosGet(`${process.env.VUE_APP_BACKEND_URL}/user/is-admin`, {
            params: {
              userId,
            },
          })
        ).data
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async getLevel(): Promise<LevelOverview[]> {
      try {
        const userStore = useUserStore()
        const result = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/level`,
          {
            params: {
              appId: userStore.userData?.appId,
            },
          }
        )

        return result.data
      } catch (error) {
        // console.error(error);
        throw error
      }
    },
  },
})
