import { EnumNumberMember } from '@babel/types'

export interface Summary {
  siteName: string
  eMail: string
  companyName: string
  street: string
  houseNumber: string
  postalCode: string
  city: string
  vat: string
  // reMail: string;
  // invoiceBetween: string;
  // invoiceOrderNr: number;
}

export class Site {
  id = 0
  name = ''
  address = {
    street: '',
    houseNumber: '',
  }
  locality = {
    zip: '',
    locality: '',
  }
}

export interface AppConfiguration {
  app: {
    id?: number
    url: string
    name: string
  }
  color: {
    hexCodeBackground: string
    hexCodeTheme: string
  }
  poolStart?: {
    memberNumber: number
    groupNumber: number
  }
  minimumAge: number
}

export interface LevelConfiguration {
  app?: {
    id: number
  }
  visits: {
    10: string
    25: string
    50: string
    75: string
    100: string
    150: string
    200: string
    300: string
  }
}

export interface CompanyConfiguration {
  appId?: string
  siteName: string
  clientMail: string
  company: {
    name: string
    vat: string
  }
  address: {
    street: string
    houseNumber: string
  }
  locality: {
    postalCode: string
    city: string
  }
  country: {
    countryDE: string
    countryCode: string
    countryEN: string
    createdAt: string
    deleted: boolean
  }
  businessTimes: BusinessHours[]
  // reMails: string;
  links: {
    website: string
    iLike: string
    agb: string
    socialMedia: string
    privacy: string
  }
  generalInfo: string
  siteId?: number
}

export enum Weekday {
  Montag = 'Montag',
  Dienstag = 'Dienstag',
  Mittwoch = 'Mittwoch',
  Donnerstag = 'Donnerstag',
  Freitag = 'Freitag',
  Samstag = 'Samstag',
  Sonntag = 'Sonntag',
}

export class BusinessHours {
  from: string | undefined
  to: string | undefined
  weekday: Weekday | undefined
  siteId?: number
  closed: boolean
  allDay: boolean
}

export interface BusinessHoursSorted {
  weekday: Weekday
  dates: [{ from: string; to: string; allday: boolean; closed: boolean }]
}

export interface SecurityPasswordConfiguration {
  passwordOld: string
  passwordNew: string
  passwordNewRepeat: string
}

export interface SecurityMailConfiguration {
  eMailOld: string
  eMailNew: string
  eMailNewRepeat: string
}

export interface BookingConfiguration {
  maxTables: number | undefined
  maxMembers: number | undefined
  maxPlayerPerTable: number | undefined
  maxGameTimePerBooking: number | undefined
  minimumBookingTimeMinute: number | undefined
  timeBetweenGames: number
  cancelDeadlineMinute: number | undefined
  prohibitBookingDays?: Array<{ date?: Date; reason?: string }>
  currentProhibits?: Array<{ date: Date; reason: string }>
  notifyOnBooking: boolean
}

export interface TableCost {
  cost: number
  day: Weekday
}

export interface QHourCost {
  cost: number
  day: Weekday
}

export interface InvoiceConfiguration {
  membershipCostPerMonth?: number
  tableCost?: TableCost[]
  tableCostGeneral?: number
  qHourCostGeneral?: number
  qHourCost?: QHourCost[]
  serviceCost?: {
    perMonth: number
    perGame: number
  }
  parkingSpotCost?: number
}

export class ParkingSpotConfiguration {
  parkingSpots: { id?: number; name: string }[]
  parkingSpotMessage = ''
  isFree: boolean
}

export class ApiAccessConfiguration {
  serverUrl = ''
  user = ''
  password?: string
  add1 = ''
  add2 = ''
}

export class ApiSmsGatewayConfigration {
  serverUrl = ''
  user = ''
  password?: string
}

export class ApiInvoiceConfiguration {
  server = ''
  username = ''
  password?: string
  odooCustomerNumber = 0
  prefixTwo = ''
}

export class ApiEmailServerConfiguration {
  smtpServer = ''
  user = ''
  password = ''
  sender = { name: '', eMail: '' }
}

export class License {
  id = 0
  local: {
    licensed: number
    used: number
  } = { licensed: 0, used: 0 }
  table: {
    licensed: number
    used: number
  } = { licensed: 0, used: 0 }
  parkingSpot: {
    licensed: number
    used: number
  } = { licensed: 0, used: 0 }
  user: {
    licensed: number
    used: number
  } = { licensed: 0, used: 0 }
  group: {
    licensed: number
    used: number
  } = { licensed: 0, used: 0 }
  vendingMachine: {
    licensed: number
    used: number
  } = { licensed: 0, used: 0 }
  provision = 0
  hardwareAndCloudServer = true
  firewallAndBackup = true
  updatesAndUpgrades = true
  appUrl = true
  appName = true
  branding = true
}

export class BookingRules {
  maxPlayers = 0
  maxTables = 0
  maxPlayersPerTable = 0
  maxBookingTime = 0
  minBookingTime = 0
  bufferBetweenGames = 0
  cancelDeadline = 0
}
export class UserToAuthorize {
  id: number
  username: string
  name: string
  surname: string
  createdAt: Date
  profilePicture: string
}

export enum UserAuthorization {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Pending = 'Pending',
  Blocked = 'Blocked',
}

export interface UserTableUser {
  membershipNumber: string
  username: string
  name: string
  surname: string
  locality: UserTableLocality
  birthDate: string
  vehicleOne: string
  vehicleTwo: string
  socialMediaActive: boolean
  createdAt: string
  bookings: UserTableBooking[]
  groups: UserTableUserGroup[]
  authorization: string
}

export interface SentMessagesTable {
  date: string
  toGroupName: string
  toUserNames: string | string[]
  message: string
  type: 'SMS' | 'EMAIL'
}

export enum PresetTypes {
  'Accept',
  'Reject',
  'Ban',
  'Invite',
  'InsufficientCredits',
  'Registration',
}

export class AppPresets {
  type: PresetTypes
  message: string
}

export interface UserTableLocality {
  zip: string
  locality: string
}

export interface UserTableBooking {
  id: number
}

export interface UserTableUserGroup {
  id: number
  name: string
}

export interface GroupTableGroup {
  id: number
  name: string
  users: GroupTableGroupUser[]
  discount: number
  createdAt: string
}

export interface GroupTableGroupUser {
  id: number
}

export interface AdminMessagePreset {
  id: number
  createdAt: Date
  message: string
  presetName: string
  clientId: number
}

export interface AdminMessageSignature {
  id: number
  createdAt: Date
  signature: string
  name: string
}

export interface AdminUserGroup {
  id: number
  name: string
}

export interface AdminGroupUser {
  id: number
  name: string
  surname: string
  username: string
}

export interface MessageData {
  groupId?: number
  presetId: number
  signatureId: number
  message: string
  appId: number
  fromUserId: number
  sendToAll?: boolean
  userId?: number
}

export class PresetConfig {
  type: 'Template' | 'Signature'
  name: string
  message: string
  id?: number
}

export class MessageConfigTable {
  type: 'Template' | 'Signature'
  name: string
  message: string
}

export class BookingVm {
  bookingId: number
  from: string
  to: string
  bookee: string
  participants: string
  tables: string[]
  cost: number
  site: string
}
