/* eslint-disable no-useless-catch */
import { defineStore } from 'pinia'
import axiosInstance from '@/globals/axios'
import { useAuthenticationStore } from './authentication'
import {
  BookingInvitation,
  InvitationStatus,
  RespondInvitation,
  SentInvitation,
} from '@/models/interfaces/Booking'
import { axiosGet, axiosPost } from '@/globals/AxiosHelper'
import { useUserStore } from './user'

export const useInvitationStore = defineStore('invitations', {
  state: () => ({
    invitations: undefined as BookingInvitation[] | undefined,
    sentInvitations: undefined as SentInvitation[] | undefined,
  }),

  getters: {
    getPendingInvitations(state): BookingInvitation[] {
      return (
        state.invitations?.filter(
          inv => inv.status === InvitationStatus.pending
        ) ?? []
      )
    },
  },
  actions: {
    /**
     * Query the database for all invitations of the logged in user.
     * @returns
     */
    async getInvitations(): Promise<BookingInvitation[]> {
      try {
        const authStore = useAuthenticationStore()
        const response = await axiosGet(
          `${process.env.VUE_APP_BACKEND_URL}/booking/invitations`,
          {
            params: {
              userId: authStore.userData?.id,
            },
          }
        )

        // Sort invitation by created Date.
        response.data.sort((a: BookingInvitation, b: BookingInvitation) => {
          const keyA = new Date(a.createdAt)
          const keyB = new Date(b.createdAt)
          // Compare the 2 dates
          if (keyA < keyB) return 1
          if (keyA > keyB) return -1
          return 0
        })

        this.$patch(state => {
          state.invitations = response.data
        })

        return this.invitations as BookingInvitation[]
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async getSentInvitations() {
      try {
        const authstore = useAuthenticationStore()

        const response = await axiosInstance.get<SentInvitation[]>(
          `${process.env.VUE_APP_BACKEND_URL}/booking/sent-invitations`,
          {
            params: {
              userId: authstore.userData?.id,
            },
          }
        )
        const invitations: SentInvitation[] = response.data

        this.$patch(state => {
          state.sentInvitations = invitations
        })
        return invitations
      } catch (error) {
        // console.error(error);
        throw error
      }
    },

    async respondInvitation(invitation: RespondInvitation) {
      try {
        await axiosPost(
          `${process.env.VUE_APP_BACKEND_URL}/booking/respond-invitation`,
          {
            bookingId: invitation.bookingId,
            userId: invitation.userId,
            invitationAction: invitation.invitationState,
          }
        )
        const userStore = useUserStore()
        await userStore.getCredits()
      } catch (error) {
        // console.error(error);
        throw error
      }
    },
  },
})
