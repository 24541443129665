import { BookingRules, BusinessHours, Weekday } from './Administration'
import { Evva, EvvaAirkey } from './EvvaAirkey'

export interface BookGame {
  info?: string
  from: string
  to: string
  splitBooking: boolean
  // parkingSpots?: BookGameParkingSpot;
  // carPlates?: Array<string>;
  tableIds?: Array<number>
  siteId: number
  // tournament?: BookGameTournament;
  userIds?: Array<number>
  phoneNumbers?: Array<string>
  evva: Evva
}

export interface PriceCalculation {
  from: string
  to: string
  siteId: number
  amountParkingSpots?: number
  amountTables: number
}

export class PriceComposition {
  game: number
  table: number
  service: number
  discount: number
}

export enum InvitationStatus {
  accepted = 'accepted',
  rejected = 'rejected',
  pending = 'pending',
}
export interface BookingInvitation {
  bookingId: number
  bookeeUserName: string
  status: InvitationStatus
  from: Date
  to: Date
  info: string
  createdAt: Date
  players: { username: string; status: InvitationStatus }[]
  tables: string[]
  splitBooking: boolean
  profilePicture: string
  price: number
  site: string
  priceComposition?: PriceComposition
}

export interface SentInvitation {
  accepted: InvitationState
  createdAt: Date
  booking: {
    from: Date
    to: Date
    info: string
  }
  user: {
    username: string
    profilePicture: string
  }
}

export interface RespondInvitation {
  bookingId: number
  userId: number
  invitationState: InvitationState
}

export interface InvitationUser {
  bookee: InvitationUsername
}
export interface InvitationUsername {
  username: string
}
export interface BookGameUser {
  userId?: number
  username?: string
  phoneId?: number
  emailId?: number
  addressId?: number
}

export interface BookGameTournament {
  id?: number
  bookingId?: number
}

export interface BookGameSite {
  id?: number
  localityId?: number
  countryCode?: string
  addressId?: number
}
export interface BookGameParkingSpot {
  from: Date
  to: Date
  booking: number
  parkingSpot: number
}

export interface BookedGame {
  locationId: number
  locationName: string
  numberOfParkingLots?: number
  licensePlates?: Array<string>
}

export interface Players {
  playerId: number
  telNumber: string
  status: string
}

export interface Parkinglots {
  amountOfParkinglots?: number
  licensePlates?: string[]
}

export interface HostedBooking {
  from: string
  to: string
  id: number
  info: string
  siteId: number
  splitBooking: boolean
  price: number
  state: 'active' | 'cancelled'
}

export interface Booking {
  accepted: InvitationState
  booking: {
    from: Date
    to: Date
    state: 'active' | 'cancelled'
    bookee: {
      username: string
    }
  }
}
export interface Game {
  participants: Array<string>
  winner: string
  duration: number
  date: string
}

export enum InvitationState {
  accepted = 'accepted',
  rejected = 'rejected',
  pending = 'pending',
}

export class ProhibitBooking {
  date: string
  reason: string
}

export class ParkingSpot {
  isFree: boolean
  message: string
}

export class BookGameData {
  siteId: number
  name: string
  bookingRules: BookingRules
  businessHours: BusinessHours[]
  prohibitBookings: ProhibitBooking[]
  parking: ParkingSpot
  tariff: {
    generalPricePerQuarterOfHour: number
    generalPricePerTable: number
    parkingCostPerBooking: number
    serviceCostPerBooking: number
    serviceCostPerMonth: number
    monthlyCost: number
    tablePrices: [
      {
        day: Weekday
        cost: number
      }
    ]
    qHourPrices: [
      {
        day: Weekday
        cost: number
      }
    ]
  }
}
export class Users {
  id: number
  username: string
}
